import React, { useState, useEffect } from 'react';

const Checklist = ({ onComplete }) => {
  const [items, setItems] = useState([
    { id: 1, text: "Is your name Claire?", checked: false },
    { id: 2, text: "Is today August 13th, 2024?", checked: false },
    { id: 3, text: "Are you holding flowers?", checked: false },
  ]);

  const [allChecked, setAllChecked] = useState(false);

  const handleCheck = (id) => {
    const updatedItems = items.map(item =>
      item.id === id ? { ...item, checked: !item.checked } : item
    );
    setItems(updatedItems);

    if (updatedItems.every(item => item.checked)) {
      setAllChecked(true);
      onComplete();
    } else {
      setAllChecked(false);
    }
  };

  useEffect(() => {
    if (allChecked) {
      const timer = setTimeout(() => {
        setAllChecked(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [allChecked]);

  return (
    <div className="checklist-container">
      <div className={`checklist ${allChecked ? 'celebrate' : ''}`}>
        <ul>
          {items.map(item => (
            <li key={item.id}>
              <label>
                <input
                  type="checkbox"
                  checked={item.checked}
                  onChange={() => handleCheck(item.id)}
                />
                <span>{item.text}</span>
              </label>
            </li>
          ))}
        </ul>
      </div>
      <style jsx>{`
        .checklist-container {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100vh;
          width: 100vw;
          background-color: #f0f8ff;
        }
        .checklist {
          width: 300px;
          padding: 20px;
          background-color: white;
          border-radius: 10px;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
          transition: all 0.3s ease;
        }
        .celebrate {
          animation: celebrate 3s ease;
        }
        ul {
          list-style-type: none;
          padding: 0;
          margin: 0;
        }
        li {
          margin-bottom: 15px;
        }
        li:last-child {
          margin-bottom: 0;
        }
        label {
          display: flex;
          align-items: center;
          cursor: pointer;
        }
        input[type="checkbox"] {
          margin-right: 10px;
          appearance: none;
          width: 20px;
          height: 20px;
          border: 2px solid #4a90e2;
          border-radius: 3px;
          outline: none;
          transition: all 0.2s ease;
        }
        input[type="checkbox"]:checked {
          background-color: #4a90e2;
          position: relative;
        }
        input[type="checkbox"]:checked::before {
          content: '✓';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: white;
          font-size: 14px;
        }
        span {
          font-size: 16px;
          color: #333;
        }
        @keyframes celebrate {
          0%, 100% { transform: scale(1); }
          50% { transform: scale(1.05); background-color: #e6f7ff; }
        }
      `}</style>
    </div>
  );
};

export default Checklist;