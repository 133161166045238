import React from 'react';
import './PhotoAlbum.css';

const bucket_url = "https://pub-4b9c9cde1cd0422baeb62c068d35c2f0.r2.dev/";

const media = [
  { 
    id: 9,
    src: bucket_url + "IMG_0163.jpg",
    date: "February",
    description: "I love seeing the world with you, I can't wait to travel far and wide."
  },
  {
    id: 8,
    src: bucket_url + "IMG_0354 2.MOV",
    date: "February",
    description: "I love doing new things with you, even when they're scary."
  },
  {
    id: 9,
    src: bucket_url + "IMG_0585.JPG",
    date: "February",
    description: "I love being silly with you, you are the funniest person I know."
  },
  {
    id: 9,
    src: bucket_url + "IMG_0643 2.MOV",
    date: "February",
    description: "I love doing cool things with you! Your brain is so fascinating."
  },
  {
    id: 9,
    src: bucket_url + "IMG_0706.jpg",
    date: "February",
    description: "I love discovering my sense of style with you!"
  },
  {
    id: 10,
    src: bucket_url + "IMG_0816.JPG",
    date: "March",
    description: "I love making memories with you, even when they're not in perfect places."
  },
  {
    id: 10,
    src: bucket_url + "IMG_0836.JPG",
    date: "March",
    description: "I love road trips with you. VROOOM!"
  },
  {
    id: 12,
    src: bucket_url + "IMG_1018.jpg",
    date: "May",
    description: "I love how you help me push myself."
  },
  {
    id: 10,
    src: bucket_url + "IMG_1055.JPG",
    date: "April",
    description: "I love spending time with all of my most important people (and dogs)."
  },
  {
    id: 14,
    src: bucket_url + "IMG_1159.jpg",
    date: "June",
    description: "I love spending time with our friends together."
  },
  {
    id: 16,
    src: bucket_url + "IMG_1234.jpg",
    date: "August",
    description: "I loved introducing you to my Irish family."
  },
  {
    id: 2,
    src: bucket_url + "IMG_1443.jpg",
    date: "September",
    description: "We enjoyed wine, weird statues, and loud umbrellas. All things typically found in a vineyard."
  },
  {
    id: 4,
    src: bucket_url + "IMG_1598.jpg",
    date: "November",
    description: "I love you because you don't mind my EBD even when it flairs up in concerts."
  },
  {
    id: 7,
    src: bucket_url + "IMG_1926.jpg",
    date: "January",
    description: "I loved becoming a parent with you, even though I am not quiet ready for the responsibility yet (RIP GORT & GART)."
  },
  {
    id: 6,
    src: bucket_url + "IMG_3699 2.JPG",
    date: "January",
    description: "I love you because you showed me birthdays can be fun."
  },
  {
    id: 3,
    src: bucket_url + "IMG_5474.JPG",
    date: "October",
    description: "During spooky season I got to see your crafty side and meet some of your friends and colleagues."
  },
  {
    id: 6,
    src: bucket_url + "IMG_5513.JPG",
    date: "January",
    description: "I love how you push me to be a better person, even when I get lazy."
  },
  {
    id: 5,
    src: bucket_url + "IMG_5857.JPG",
    date: "December",
    description: "I love learning life skills with you, even when they're embarrassing."
  },
  {
    id: 3,
    src: bucket_url + "IMG_5185.jpg",
    date: "October",
    description: "I love sharing time with your friends, they have all become friends of mine."
  },
  {
    id: 3,
    src: bucket_url + "IMG_5191.jpg",
    date: "October",
    description: "I love our differences, they make us stronger. (You love dessert more than me)"
  },
  {
    id: 3,
    src: bucket_url + "IMG_5216.JPG",
    date: "October",
    description: "I love starting new traditions with you, and thinking about the future."
  },
  {
    id: 1,
    src: bucket_url + "IMG_4781.JPG",
    date: "September", 
    description: "Our year began on the beaches of California. I distinctly remember this moment, knowing that meeting you would change my life forever."
  },
  {
    id: 11,
    src: bucket_url + "IMG_0899.JPG",
    date: "April",
    description: "I love trying new food with you even if its sometimes too much! 🦆."
  },
  {
    id: 11,
    src: bucket_url + "IMG_7814.jpeg",
    date: "April",
    description: "I love hosting parties with you! You are the best host."
  },
  {
    id: 11,
    src: bucket_url + "IMG_0868.jpg",
    date: "April",
    description: "I love quiet moments with you and our puppies!"
  },
  {
    id: 14,
    src: bucket_url + "IMG_0144.JPG",
    date: "July", 
    description: "I love how you take interest in my nerdy obsessions, even when it's not so cool!"
  },
  {
    id: 31,
    src: bucket_url + "IMG_1254.jpg",
    date: "August", 
    description: "I love playing golf with you. I can't wait to play more!"
  },
  {
    id: 35,
    src: bucket_url + "IMG_0174.jpg",
    date: "Forever", 
    description: "I love you because you are my best friend, my partner in crime, and my favorite person to spend time with."
  }
];

const PhotoAlbum = () => {
  const isVideo = (filename) => {
    return filename.toLowerCase().endsWith('.mov');
  };

  return (
    <div className="photo-album">
      {media.sort((a,b)=> a.id - b.id).map((item) => (
        <div key={item.id} className="photo-wrapper">
          <div className="content-container">
            <div className="text-content">
              {/* <div className="date">{item.id}</div> */}
              <div className="date">{item.date}</div>
              <p className="description">{item.description}</p>
            </div>
            <div className="photo-container">
              {isVideo(item.src) ? (
                <video src={item.src} className="photo" autoPlay playsInline loop muted>
                  Your browser does not support the video tag
                </video>
              ) : (
                <img src={item.src} alt={item.description} className="photo" />
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default PhotoAlbum;