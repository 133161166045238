import React, { useState } from 'react';
import Checklist from './CheckList';
import PhotoAlbum from './PhotoAlbum';
import './App.css';

const App = () => {
  const [isChecklistComplete, setIsChecklistComplete] = useState(false);

  const handleChecklistComplete = () => {
    setIsChecklistComplete(true);
  };

  return (
    <div className="app">
      {!isChecklistComplete ? (
        <Checklist onComplete={handleChecklistComplete} />
      ) : (
        <PhotoAlbum />
      )}
    </div>
  );
};

export default App;